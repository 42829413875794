import React from 'react';
import bigQueryDashboard from '../images/BigQueryMCP.png';
import patentAnalysis from '../images/patents_bigquery.jpeg';
import '../../../styles/blog.css';

const Content = () => (
  <div>
    <p>Happy to release the latest version of our Model Context Protocol (MCP) server for biomedical data in BigQuery. 
       This enables interaction with comprehensive biomedical data sources using Claude AI or any MCP-compatible 
       LLM client. With MCP we're reducing hallucinations and let LLMs interact directly with the databases.</p>

    <div className="image-container">
      <img 
        src={bigQueryDashboard}
        alt="Interactive dashboard analysis using OpenTargets data in BigQuery"
        className="blog-image"
        style={{ width: '66%', height: 'auto' }}
      />
      <p className="image-caption">
        Interactive dashboard analysis using OpenTargets data through our MCP interface
      </p>
    </div>

    <div className="image-container">
      <img 
        src={patentAnalysis}
        alt="Analysis of bispecific antibody patents"
        className="blog-image"
        style={{ width: '66%', height: 'auto' }}
      />
      <p className="image-caption">
        Analysis of bispecific antibody patents using our MCP interface
      </p>
    </div>

    <h2>Try it yourself</h2>
    <p>Check out our <a href="https://github.com/navisbio/mcp_bigquery_biomedical" 
       target="_blank" rel="noopener noreferrer">GitHub repo</a> for source code and setup instructions.</p>

    <h2>Need more data? </h2>
    <p>While public BigQuery databases may be a bit dated, we're developing proprietary databases and tools 
       for AI-powered translational research. Do you need access to current patent data, clinical trials, and more? Get in touch at <a href="mailto:Nives.Rombini@navis-bio.com">Nives.Rombini@navis-bio.com</a>!</p>

    <h2>Local Deployment</h2>
    <p>We're also developing our own MCP client. What it means for you? You can deploy this locally and use it with any LLM.</p>
  </div>
);

export const post = {
  title: "Analyze Biomedical Data with AI: Introducing BigQuery MCP",
  date: "2025-01-03",
  Content
};