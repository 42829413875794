// Replace dynamic imports with static imports
import { post as welcomePost } from '../content/blog/posts/welcome-post.jsx';
import { post as mcpCtgovPost } from '../content/blog/posts/MCP-ctgov.jsx';
import { post as ashDashboardPost } from '../content/blog/posts/ASH-dashboard.jsx';
import { post as mcpBigqueryPost } from '../content/blog/posts/MCP-bigquery.jsx';

const blogPosts = [
  {
    slug: 'welcome-post',
    ...welcomePost,
    isJsx: true
  },
  {
    slug: 'MCP-ctgov',
    ...mcpCtgovPost,
    isJsx: true
  },
  {
    slug: 'ASH-dashboard',
    ...ashDashboardPost,
    isJsx: true
  },
  {
    slug: 'MCP-bigquery',
    ...mcpBigqueryPost,
    isJsx: true
  }
];

export function getBlogPosts() {
  return blogPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
}

export function getPostBySlug(slug) {
  const post = blogPosts.find(post => post.slug === slug);
  if (!post) {
    throw new Error(`Post with slug "${slug}" not found`);
  }
  return post;
} 